<div  class="layout-grid">
	<div class="menu-items">
		<ul app-submenu style="height: 75%"  [item]="model" routerLinkActive="stock-management" root="true" class="ultima-menu ultima-main-menu clearfix"
			visible="true" parentActive="true">
		</ul>
	</div>
	<div class="relative">
		<div class="versions-section" *ngIf="versions">
			<div *ngIf="showVersions" style="font-size: 11px">
				<p>Authentication Version: {{versions.auth}}</p>
				<p>Dealership Version: {{versions.dealership}}</p>
				<p>Migrations Version: {{versions.migrations}}</p>
				<p>Reports Version: {{versions.reports}}</p>
				<p>Transunion Version: {{versions.transunion}}</p>
			</div>
			Version: {{versions.website}} <i class="pi pi-chevron-up text-xs cursor-pointer" (click)="showHideVersions()"></i>
		</div>
	</div>
</div>


