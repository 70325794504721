import {Component, OnInit, Input, ViewEncapsulation, ViewChild} from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {TelerikReportViewerComponent} from "@progress/telerik-angular-report-viewer";
import {ObjectHelpers} from "../../helpers/object-helpers";

@Component({
	selector: 'app-report-previewer',
	templateUrl: './report-previewer.component.html',
	styleUrls: ['./report-previewer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ReportPreviewerComponent implements OnInit {
	@Input() reportDetails: any;
	@Input() parameters: any;
	@ViewChild('reportPreviewer', { static: true }) reportPreviewer: TelerikReportViewerComponent;

	baseUrl = 'https://staging-reports.vmgonlineservices.co.za/api/reports/';
	reportConfig = {};
	title: string;
	viewerContainerStyle = {
		position: 'relative',
		width: '60vw',
		height: '80vh',
		['font-family']: 'ms sans serif'
	};

	constructor(private _config: DynamicDialogConfig, ) {}

	ngOnInit(): void {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(this._config) && !ObjectHelpers.isNullUndefinedOrEmpty(this._config.data)) {
			this.reportDetails = this._config.data['reportDetails'] || this.reportDetails;
			this.parameters = this._config.data['parameters'] || this.parameters;
		}

		this.title = this.parameters?.ReportName || '';
		this.reportConfig = {
			report: `${this.reportDetails?.ReportName}.trdp`,
			parameters: this.parameters,
		};
	}

	public refreshReport(): void {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(this.reportPreviewer)) {
			this.reportPreviewer.refreshReport();
		}
	}
}
