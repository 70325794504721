import {Injectable} from '@angular/core';
import {GenericEntityService} from '../shared/generic-entity.service';
import {ApiService} from '../shared/api.service';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {HttpClient} from '@angular/common/http';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {Globals} from '../../pages/shared/globals';
import {ICode} from '../../models/settings/code';
import {RequestOptions} from '../../models/shared/request-options.model';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../../models/IResponseWrapper';
import {IRecycledCode} from '../../models/settings/recycled-code';
import {IGetCodeResponse} from '../../models/settings/get-code-response';
import {IGeneralSettingsResponse} from '../../models/settings/general-settings-response';
import {ITenantSettings} from '../../models/settings/tenant-settings';


@Injectable({
	providedIn: 'root'
})
export class SettingsService extends GenericEntityService<ICode, string> {

	constructor(protected apiService: ApiService,
				protected cacherService: CacherService,
				protected http: HttpClient,
				protected notificationsService: NotificationsService
	) {
		const baseUrl: string = Globals.SettingsBaseUrl + '/Settings';
		super(notificationsService, apiService, baseUrl);
	}

	public setCode(payload: ICode[], requestOptions: RequestOptions): Observable<ResponseWrapper<ICode[]>> {
		requestOptions.method = 'post';
		const endpoint = 'SetCode';
		requestOptions.baseUrl = Globals.SettingsBaseUrl + `/Settings`;

		return this.apiService.apiRequest<ResponseWrapper<ICode[]>>(endpoint, payload, requestOptions);
	}

	public recycleCode(recycledCode: IRecycledCode, requestOptions: RequestOptions): Observable<ResponseWrapper<[]>> {
		requestOptions.method = 'post';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = Globals.SettingsBaseUrl + `/Settings/RecycleCode`;

		return this.apiService.apiRequest<ResponseWrapper<[]>>(endpoint, recycledCode, requestOptions);
	}

	public getCode(entityCodeName: string, branchId: number,
				   requestOptions: RequestOptions): Observable<IGetCodeResponse> {
		requestOptions.method = 'get';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = Globals.SettingsBaseUrl + `/Settings/GetCode/${entityCodeName}/${branchId}`;

		return this.apiService.apiRequest<IGetCodeResponse>(endpoint, branchId, requestOptions);
	}

	public getGeneralSettings(branchId: number, requestOptions: RequestOptions): Observable<ResponseWrapper<IGeneralSettingsResponse>> {
		requestOptions.method = 'get';
		const endpoint: string = requestOptions.endpointUrl;

		requestOptions.baseUrl = Globals.SettingsBaseUrl + `/Settings/GetGeneralSettings/${branchId}`;

		return this.apiService.apiRequest<ResponseWrapper<IGeneralSettingsResponse>>(endpoint, branchId, requestOptions);
	}

	public updateTenantSettings(tenantSettings: ITenantSettings[], requestOptions: RequestOptions): Observable<IGeneralSettingsResponse> {
		requestOptions.method = 'put';
		const endpoint: string = 'BulkUpdate';
		requestOptions.baseUrl = Globals.SettingsBaseUrl + `/Settings/TenantSettings`;

		return this.apiService.apiRequest<IGeneralSettingsResponse>(endpoint, tenantSettings, requestOptions);
	}
}
