<form class="grid p-fluid login-page" [formGroup]="loginForm" autocomplete="off">
	<div class="col-12 padding">
		<p class="m-0">Your session is inactive. Please enter your password to continue your session. If you sign out, any unsaved
			work, will be lost.</p>
	</div>
	<div class="flex col-12 justify-content-center">
		<div class="padding">

			<div class="p-inputgroup">
				<span class="p-float-label">
					<input [type]="passwordType"
						   formControlName="password"
						   class="input"
						   pInputText
						   (click)="$event.target.select()"
						   (ngModelChange)="valueChanged('password')"
						   (keyup)="checkCapsLock($event)"
						   (keyup.enter)="login()">
					<label>Password</label>
				</span>
				<span class="p-inputgroup-addon" style="cursor: pointer">
					<div class="col-2" style="padding-top: 0;padding-bottom: 0;"
						 (click)="togglePassword()">
						<label *ngIf="showPassword" class="fa fa-eye"></label>
						<label *ngIf="!showPassword" class="fa fa-eye-slash"></label>
					</div>
				</span>
			</div>
			<span *ngIf="capsLock" class="caps-warning">
				<i class="ui-icon-lock" style="vertical-align:baseline;font-size:0.7rem;"></i> CAPS LOCK is On.
			</span>
		</div>
	</div>
	<div class="flex col-12 justify-content-between padding">
		<button pButton type="button" label="Sign out" style="width: auto" icon="pi pi-power-off"
				(click)="redirectToLogin()"></button>
		<button pButton type="button" label="Continue" style="width: auto" icon="ui-icon-person"
				(click)="login()"></button>
	</div>
</form>

