import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {NotificationsService} from '../../shared/services/notifications/notifications.service';
import {CacherService} from '../../shared/services/cacher/cacher.service';
import {LoginRequestModel} from './models/login-request.model';
import {ObjectHelpers} from '../../shared/helpers/object-helpers';
import {ErrorResponse} from '../../../services/shared/api.service';
import {StringHelpers} from '../../shared/helpers/string-helpers';
import {lastValueFrom} from 'rxjs';
import {BRANCH_IDS, COMPANY_ID, CompanyService} from '../services/company/company.service';
import {PermissionService} from '../../shared/services/permissions/permission.service';
import {CacheType} from '../../shared/services/cacher/caching-options';
import {Globals} from '../../shared/globals';
import {RequestOptions} from '../../../models/shared/request-options.model';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	public loginDetails: LoginRequestModel = new LoginRequestModel();
	public loginFormActive: boolean = true;
	public emailAddressToReset: string = '';

	constructor(private _router: Router,
				private companyService: CompanyService,
				private _authService: AuthService,
				private _notificationsService: NotificationsService,
				private _cacherService: CacherService,
				private permissionService: PermissionService) {
	}

	public ngOnInit(): void {
	}

	public isLoginFormValid(isValid): boolean {
		return isValid;
	}

	public isLoginForm(): Promise<boolean> {
		return this.validateLoginForm();
	}

	public isResetPasswordFormValid(isValid): boolean {
		return isValid;
	}

	public isResetForm(): Promise<boolean> {
		return this.validateResetPasswordForm();
	}

	public returnToLogin(): void {
		this.loginFormActive = true;
	}

	public forgotPassword(): void {
		this.loginFormActive = false;
	}

	public async resetPassword(): Promise<void> {

		if (!await this.isResetForm()) {
			return;
		}

		const resetPassword$ = this._authService.resetPassword(this.emailAddressToReset, new RequestOptions());
		await lastValueFrom(resetPassword$).then(() => {
			this._notificationsService.showSuccessToastMessage('Request Successful', `Password change request sent to ${this.emailAddressToReset}`);
		}).catch(error => {
			this._notificationsService.showErrorToastMessage('Request Unsuccessful', 'Password change request could not be sent to specified email address \n' +
				'Please contact support.');
			this._authService.logout();
		});
	}


	public async login(): Promise<void> {
		if (!await this.isLoginForm()) {
			return;
		}

		const login$ = this._authService.login(this.loginDetails);
		await lastValueFrom(login$).then(async (loginResponse) => {
			this._authService.loginResponse = loginResponse;
			await this.companyCheck()
				.catch(async (err: ErrorResponse) => {
					this._authService.logout();
				});
		});
	}

	private async companyCheck(): Promise<void> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(this._authService.loginResponse)) {

			if (this._authService.loginResponse.employeeUserInformation.length === 1) {
				await this.setCachingOnLogin();

				await this._router.navigate(['/']);
			} else {

				this._cacherService.setData('AllBranches', this._authService.loginResponse, {cacheType: CacheType.Local});

				this.companyService.userInformation = this._authService.loginResponse;
				await this._router.navigate(['/company-select']);
			}
		} else {
			this._notificationsService.showErrorToastMessage('Login failed', 'This user account does not have any active Dealership instances available. ' +
				'Please contact support for more information.');
			this._notificationsService.hideLoader();
		}
	}

	private async setCachingOnLogin(): Promise<void> {

		try {
			const loginResponse = this._authService.loginResponse;

			await this._authService.setAuthCacheValues(loginResponse.user.id, loginResponse.accessToken, loginResponse.refreshToken, loginResponse.employeeUserInformation[0].employee);
			this._cacherService.setData('UserInformation', loginResponse, {cacheType: CacheType.Local});

			this.permissionService.addRoleWithPermissions(loginResponse.employeeUserInformation[0]);

			Globals.CompanyId = loginResponse.employeeUserInformation[0].companyId;
			Globals.BranchIds = [loginResponse.employeeUserInformation[0].employee.branchId];

			this._cacherService.setData(BRANCH_IDS, Globals.BranchIds, {cacheType: CacheType.Local});
			this._cacherService.setData(COMPANY_ID, Globals.CompanyId, {cacheType: CacheType.Local});

		} catch (e) {
			this._authService.logout();
			await this._router.navigate(['/login']);
		}
	}

	private async validateLoginForm(): Promise<boolean> {
		if (StringHelpers.isNullOrWhiteSpace(this.loginDetails.emailAddress)) {
			this._notificationsService.showErrorToastMessage('Alert', 'Email Address required.');
			return false;
		}

		if (StringHelpers.isNullOrWhiteSpace(this.loginDetails.password)) {
			this._notificationsService.showErrorToastMessage('Alert', 'Password required.');
			return false;
		}

		return true;
	}

	private async validateResetPasswordForm(): Promise<boolean> {
		if (StringHelpers.isNullOrWhiteSpace(this.emailAddressToReset)) {
			this._notificationsService.showErrorToastMessage('Alert', 'Email Address required.');
			return false;
		}

		return true;
	}
}
