<div class="layout-content" style="padding-left:10px; padding-right:10px; padding-top: 0;">
	<div class="grid" style="height: 100%; overflow: auto;">
		<div id="page-content" class="col-12 px-0 h-full" >
			<div class="flex flex-wrap h-full">
				<div class="col-3">
					<div class="flex flex-wrap gap-3">
						<p-panel class="w-full">
							<p-header class="w-full">
								<div class="flex flex-wrap align-items-center justify-content-between w-full">
									<div>
										Report Types
									</div>
									<div style="text-align: right;">
										<button pTooltip="{{selectAllTooltip}}" icon="{{selectAllIcon}}" pButton type="button"
												class="blue-grey-btn" (click)="selectAll()"></button>
									</div>
								</div>
							</p-header>
							<p-table [value]="reportTypes"
									 selectionMode="multiple"
									 [(selection)]="selectedReportType"
									 [metaKeySelection]="true"
									 (onRowSelect)="changeReportList()">
								<ng-template pTemplate="body" let-rowData>
									<tr [pSelectableRow]="rowData">
										<td>
											{{ rowData }}
										</td>
									</tr>
								</ng-template>
							</p-table>
						</p-panel>

					</div>
				</div>
				<div class="col-4 h-full">
					<div class="flex flex-column gap-3 h-full">
						<p-panel class="w-full" [ngStyle]="{'height': '70%'}">
							<p-header class="flex flex-wrap justify-content-between w-full align-items-center">
								<div class="col-4 p-0">
									Report List
								</div>
								<div class="col-8 flex flex-wrap justify-content-end p-0">
									<div class="col global-search"
										 style="max-height: 35px; max-width: 230px; text-align: left;
									 margin-right: 10px; margin-left: 10px; display: flex; align-items: center;">
										<input [(ngModel)]="reportsSearchInput"
											   (keyup)="searchReportList()"
											   type="text"
											   pInputText
											   size="50"
											   placeholder="Search Reports"
											   style="width: 85% !important; color: #000;">
										<i class="inline-icon material-icons" >search</i>
										<i class="inline-icon material-icons" (click)="reportsSearchInput = '';searchReportList()" >close</i>
									</div>
								</div>
							</p-header>
							<p-table [value]="reportList"
									 scrollable="true"
									 [scrollHeight]="'52vh'"
									 selectionMode="single"
									 [(selection)]="selectedReportList"
									 [metaKeySelection]="true"
									 (onRowSelect)="previewReport()">
								<ng-template pTemplate="body" let-rowData>
									<tr [pSelectableRow]="rowData">
										<td>
											{{ this.formatReportName(rowData) }}
										</td>
									</tr>
								</ng-template>
							</p-table>
						</p-panel>
						<p-panel class="w-full relative" [ngStyle]="{'height': '30%'}" >
							<p-header class="flex flex-wrap justify-content-between w-full align-items-center">
								<div>
									Report Parameters
								</div>
							</p-header>
							<div style="padding: 0.5em 0.9em;">
								<div class="w-full">
									<div *ngIf="!disablePeriodRange">
										<div class="flex flex-wrap gap-2 w-full mb-1 justify-content-between">
											<div class="col-5 p-0"><span class="text-xs">Period Range</span></div>
											<div class="col-6 p-0">
												<p-calendar
													[showIcon]="true"
													styleClass="w-full"
													[(ngModel)]="dateRange"
													dateFormat="dd/mm/yy"
													selectionMode="range"
													[readonlyInput]="true"
												(ngModelChange)="previewReport()"></p-calendar>
											</div>
										</div>
									</div>
									<div class="text-xs" *ngIf="showIsVat">
										<p-checkbox (onChange)="previewReport()" binary="true" [(ngModel)]="isVat" label="Vat Included"></p-checkbox>
									</div>
								</div>
								<div class="absolute" style="bottom: 0.5rem; right: 0.9rem" >
									<button [disabled]="!reportConfig"
											(click)="reportPreviewer.setReportSource(this.reportConfig); reportPreviewer.refreshReport();"
											pTooltip="Preview" pButton type="button"
											class="flat mt-2" >Preview</button>
								</div>
							</div>
						</p-panel>
					</div>
				</div>
				<div class="col-5">
					<p-panel >
						<p-header class="flex flex-wrap justify-content-between w-full align-items-center">
							<div>
								Report Preview
							</div>
							<div class="flex flex-wrap gap-2">
								<button [disabled]="!reportConfig"
										pTooltip="Expand" icon="fa fa-arrows-alt" pButton type="button"
										class="blue-grey-btn" (click)="expandReport()" ></button>
								<button [disabled]="!reportConfig" pTooltip="Open in new tab"
										icon="fa fa-external-link" pButton type="button"
										class="blue-grey-btn" (click)="openReportInNewTab()" ></button>
								<button pTooltip="Download as PDF" icon="fa fa-download" pButton type="button"
										class="blue-grey-btn" [disabled]="!reportConfig"
										(click)="reportPreviewer.commands.export.exec( 'pdf')" ></button>
							</div>
						</p-header>
						<div *ngIf="this.userInformation">
							<tr-viewer #reportPreviewer
									   class="tr-viewer-hide"
									   [containerStyle]="viewerContainerStyle"
									   [serviceUrl]="baseUrl"
									   [reportSource]="reportConfig"
									   [viewMode]="'PRINT_PREVIEW'"
									   [scaleMode]="'FIT_PAGE_WIDTH'"
									   [authenticationToken]="defaultParameters['Token']"
									   [scale]="1.0">
							</tr-viewer>
						</div>
					</p-panel>
				</div>
			</div>
		</div>
	</div>
</div>
