<div class="relative w-full h-full">
	<img class="vmg-logo-main" src="https://vmgonline.s3.af-south-1.amazonaws.com/websites/vmg-os/images/vmgLogo.png" alt="VMG Logo">
</div>
<div class="flex flex-wrap justify-content-center">
	<div>
		<a href="https://www.vmgsoftware.co.za" target="_blank" class="m-1">
			<i class="pi pi-globe text-xl main-icon"></i>
		</a>
	</div>
	<div class="relative">
		<a class="m-1 cursor-pointer">
			<i (click)="showHideSupportPopup()" class="pi pi-comment text-xl main-icon"></i>
		</a>
		<div class="absolute support-main-popup" *ngIf="showSupportPanel">
			<div class="flex-wrap flex align-items-center">
				<div class="col-2">
					<i class="pi pi-phone text-xl green-icon"></i>
				</div>
				<div class="col-10">
					<p style="color: #8BC34A"><strong>Need Support?</strong></p>
					<p class="text-xs">Call us on <a class="underline" href="tel:+27 87 702 6300">+27 87 702 6300</a></p>
					<p class="text-xs">WhatsApp us <a class="underline" href="https://wa.me/+27645038382" target="_blank">+27 64 503 8382</a></p>
				</div>
			</div>
			<div class="flex-wrap flex align-items-center">
				<div class="col-2">
					<i class="pi pi-envelope green-icon text-xl"></i>
				</div>
				<div class="col-10">
					<p style="color: #8BC34A"><strong>Email Us:</strong></p>
					<p class="text-xs"><a class="underline" href="mailto:support@vmgsoftware.co.za">support@vmgsoftware.co.za</a></p>
				</div>
			</div>
			<div class="flex-wrap flex align-items-center">
				<div class="col-2">
					<i class="pi pi-calendar green-icon text-xl"></i>
				</div>
				<div class="col-10">
					<p style="color: #8BC34A"><strong>Office Hours:</strong></p>
					<p class="text-xs">Mondays - Fridays</p>
					<p class="text-xs">08:30 - 16:30</p>
					<p class="text-xs">Public Holidays: Closed</p>
				</div>
			</div>
		</div>
	</div>
</div>
