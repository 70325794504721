import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AppMainComponent} from '../../../app.main.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit, OnDestroy {
	public showSupportPanel: boolean = false;

	constructor(private _appMainComponent: AppMainComponent,
				private changeDetectorRef: ChangeDetectorRef) {
	}

	public ngOnInit(): void {
		this._appMainComponent.pageHeading = 'Main Page';
		this.changeDetectorRef.detectChanges();
	}

	public ngOnDestroy(): void {
		this.showSupportPanel = false;
	}

	public showHideSupportPopup(): void {
		this.showSupportPanel = !this.showSupportPanel;
	}




}
