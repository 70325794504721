import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppMainComponent} from './app.main.component';
import {AuthGuard} from './pages/authentication/login/auth.guard';
import {LoginComponent} from './pages/authentication/login/login.component';
import {CompanySelectComponent} from './pages/authentication/company-select/company-select.component';
import {
	CompanySelectAuthGuard
} from './pages/authentication/company-select/company-guard/company-select-auth-guard.guard';
import {MainPageComponent} from './pages/modules/main-page/main-page.component';
import {EmailVerificationComponent} from "./pages/email-verification/email-verification.component";


const routes: Routes = [
	{
		path: 'login', component: LoginComponent
	},
	{
		path: 'company-select', component: CompanySelectComponent, canActivate: [CompanySelectAuthGuard]
	},
	{
		path: '', component: AppMainComponent,
		children: [
			{path: '', component: MainPageComponent, canActivate: [AuthGuard]},
			{path: 'main', component: MainPageComponent, canActivate: [AuthGuard]},
			{path: 'stock-management', loadChildren: () => import('./pages/modules/stock-management/stock-management.module').then(m => m.StockManagementModule), canActivate: [AuthGuard]},
			{path: 'settings', loadChildren: () => import('./pages/modules/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard]},
			{path: 'company', loadChildren: () => import('./pages/modules/company-settings/company.module').then(m => m.CompanyModule), canActivate: [AuthGuard]},
			{path: 'profile', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard]},
			{path: 'reports', loadChildren: () => import('./pages/modules/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard]},
			{path: 'suppliers', loadChildren: () => import('./pages/modules/suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [AuthGuard]},
			{path: 'clients', loadChildren: () => import('./pages/modules/clients/clients.module').then(m => m.ClientsModule), canActivate: [AuthGuard]},
		]
	},
	{
	path: 'email-verification', title: 'email-verification', component: EmailVerificationComponent
	},
];

export const AppRoutingModule: ModuleWithProviders<any> = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
