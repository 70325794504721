import {Injectable} from '@angular/core';

import {Observable, of, throwError} from 'rxjs';
import {catchError, mergeMap, timeout} from 'rxjs/operators';

import {CacherService} from '../../shared/services/cacher/cacher.service';
import {NotificationsService} from '../../shared/services/notifications/notifications.service';
import {ObjectHelpers} from '../../shared/helpers/object-helpers';
import {Logger} from '../../shared/services/logger/logger';
import {Globals} from '../../shared/globals';
import {ApiService, ErrorResponse} from '../../../services/shared/api.service';

import {LoginRequestModel} from './models/login-request.model';
import {JwtHelperService} from '@auth0/angular-jwt';
import {StringHelpers} from '../../shared/helpers/string-helpers';

import {IEmployee} from '../../../models/auth/employee';
import {ISsoUserToken} from '../../../models/auth/sso-user-token';
import {RequestOptions} from '../../../models/shared/request-options.model';
import {IRefreshTokenPayload} from '../../../models/auth/refresh-token-payload';
import {UserInformation} from './models/login-response.model';
import {TVerified} from '../../../models/auth/verified';
import {UserUpdateRequest} from '../../../models/auth/user-update-request';


const jwtHelperService = new JwtHelperService();


@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private _apiService: ApiService,
				private _cacherService: CacherService,
				private _notificationsService: NotificationsService
	) {
	}

	public loginResponse: UserInformation;

	private static setAuthGlobalValues(userId: string, authToken: string, refreshToken: string, employee: IEmployee): void {
		Globals.UserId = userId;
		Globals.AuthToken = authToken;
		Globals.RefreshToken = refreshToken;
		Globals.Employee = employee;
	}

	public verifyEmail(email: string, requestOptions: RequestOptions): Observable<TVerified> {

		try {
			if (!StringHelpers.isNullOrWhiteSpace(email)) {
				requestOptions.method = 'get';
				const endpoint: string = requestOptions.endpointUrl;
				requestOptions.baseUrl = `${Globals.AuthApiBaseUrl}/email-verification/${email}`;
				return this._apiService.apiRequest<TVerified>(endpoint, null, requestOptions);
			}
		} catch (error) {
			Logger.logError(error);
		}
	}

	public login(loginRequest: LoginRequestModel): Observable<UserInformation> {
		if (ObjectHelpers.isNullUndefinedOrEmpty(loginRequest)) {
			Logger.logError(`[LOGIN] Email Address and Password was not specified.`);
			return throwError(`Email Address and Password was not specified.`);
		}

		const loginPageRequest$: Observable<UserInformation> = this._apiService.apiRequest<UserInformation>('login', loginRequest, {
			baseUrl: Globals.AuthApiBaseUrl,
			method: 'post',
			timeout: 30000,
			showLoader: true,
			useToken: false
		});

		return of({}).pipe(
			mergeMap(() => loginPageRequest$),
			timeout(30000),
			catchError(error => {
				this._notificationsService.hideLoader();
				Logger.logError(`[LOGIN] Login failed with the specified user credentials. Error: `, error);
				this.getAuthErrorResponse(error);
				return throwError(error);
			})
		);
	}

	public refreshToken(refreshToken: IRefreshTokenPayload, requestOptions: RequestOptions): Observable<ISsoUserToken> {
		if (ObjectHelpers.isNullUndefinedOrEmpty(refreshToken)) {
			Logger.logError(`[REFRESH TOKEN] Refresh Token specified cannot be null or empty.`);
			return throwError(`Refresh Token specified cannot be null or empty.`);
		}

		requestOptions.method = 'post';
		const endpoint: string = 'refreshToken';
		requestOptions.baseUrl = Globals.AuthApiBaseUrl;
		return this._apiService.apiRequest<ISsoUserToken>(endpoint, refreshToken, requestOptions);
	}

	public updatePassword(id: string, userUpdateRequest: UserUpdateRequest, requestOptions: RequestOptions): Observable<void> {

		try {
			requestOptions.method = 'post';
			const endpoint: string = `update-password/${id}`;
			requestOptions.baseUrl = Globals.AuthApiBaseUrl;
			return this._apiService.apiRequest(endpoint, userUpdateRequest, requestOptions);

		} catch (error) {
			Logger.logError(error);
		}
	}

	public resetPassword(emailAddress: string, requestOptions: RequestOptions): Observable<TVerified> {

		try {
			if (ObjectHelpers.isNullOrUndefined(emailAddress)) {
				Logger.logError(`[RESET PASSWORD] Email specified cannot be null or empty.`);
				return throwError(() => new Error(`[RESET PASSWORD] Email specified cannot be null or empty.`));
			}

			requestOptions.method = 'get';
			const endpoint: string = requestOptions.endpointUrl;
			requestOptions.baseUrl = `${Globals.AuthApiBaseUrl}/password-reset/${emailAddress}`;
			return this._apiService.apiRequest<TVerified>(endpoint, null, requestOptions);

		} catch (error) {
			Logger.logError(error);
		}
	}

	public async setAuthCacheValues(userId: string, authToken: string, refreshToken: string, employee: IEmployee): Promise<void> {
		AuthService.setAuthGlobalValues(userId, authToken, refreshToken, employee);
	}

	private async getAuthErrorResponse(errorResponse: ErrorResponse): Promise<void> {
		if (errorResponse.statusCode >= 400 && errorResponse.statusCode < 500) {
			this._notificationsService.showErrorToastMessage('Unauthorized', errorResponse.error);
		} else {
			this._notificationsService.showErrorToastMessage('Alert', 'Login failed due to an error on the server. ' +
				'Please try again and if the problem persists, please contact support.');
		}
	}

	public logout(): void {
		AuthService.setAuthGlobalValues('', '', '', null);
		this._cacherService.clearCache();
	}

	public decodeToken(token: string): any {
		return jwtHelperService.decodeToken(token);
	}

	public getTokenExpirationDate(token: string): Date | null {
		return jwtHelperService.getTokenExpirationDate(token);
	}

	public isTokenExpired(token: string, offsetSeconds?: number): boolean {
		return jwtHelperService.isTokenExpired(token, offsetSeconds);
	}

}
