import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportPreviewerComponent} from './components/report-previewer/report-previewer.component';
import {TelerikReportingModule} from '@progress/telerik-angular-report-viewer';
import {
	MultipleReportPreviewerComponent
} from './components/multiple-report-previewer/multiple-report-previewer.component';
import {TabViewModule} from 'primeng/tabview';
import {PanelModule} from "primeng/panel";
import {CalendarModule} from "primeng/calendar";
import { NotificationsComponent } from './components/notifications/notifications.component';
import {NgxPermissionsModule, NgxPermissionsRestrictStubModule} from "ngx-permissions";


@NgModule({
	declarations: [ReportPreviewerComponent, MultipleReportPreviewerComponent, NotificationsComponent],
    imports: [
        CommonModule,
        TelerikReportingModule,
        TabViewModule,
        PanelModule,
        CalendarModule,
        NgxPermissionsModule,
        NgxPermissionsRestrictStubModule
    ],
    exports: [ReportPreviewerComponent, MultipleReportPreviewerComponent, NotificationsComponent]
})
export class SharedModule {
}
