import {Injectable} from '@angular/core';
import {EncryptRequestOrResponse} from '../../../models/shared/encrypt-request-or-response';
import {StringHelpers} from '../helpers/string-helpers';
import {Globals} from '../globals';
import {ResponseWrapper} from '../../../models/IResponseWrapper';
import * as CryptoJS from 'crypto-js';
import {ObjectHelpers} from '../helpers/object-helpers';


@Injectable({
	providedIn: 'root'
})
export abstract class EncryptionService<TEntity, TKey> {

	constructor() {}

	public encryptMultipleEntityByType<Type>(entity: Type[]): EncryptRequestOrResponse {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(entity))
		return this.encryptEntities(entity);
	}

	public encryptMultipleEntity(entity: TEntity[]): EncryptRequestOrResponse {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(entity))
		return this.encryptEntities(entity);
	}

	private encryptEntities<Type>(entity: Type[]): EncryptRequestOrResponse {
		if (StringHelpers.isNullOrWhiteSpace(Globals.SecurityKey)) {
			throw new Error('Security key is not set.');
		}

		try {
			const jsonData = JSON.stringify(entity);
			const iv = CryptoJS.lib.WordArray.random(16);
			const key = CryptoJS.enc.Utf8.parse(Globals.SecurityKey.padEnd(32).substring(0, 32));

			const encrypted = CryptoJS.AES.encrypt(jsonData, key, {
				iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});

			const combined = iv.concat(encrypted.ciphertext);

			return {
				cipherText: CryptoJS.enc.Base64.stringify(combined),
			};
		} catch (error) {
			throw new Error(`An error occurred during encryption: ${error.message}`);
		}
	}

	public encryptSingleEntity(entity: TEntity): EncryptRequestOrResponse {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(entity))
		return this.encryptEntity(entity);
	}

	public encryptSingleEntityByType<Type>(entity: Type): EncryptRequestOrResponse {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(entity))
		return this.encryptEntity(entity);
	}

	private encryptEntity<Type>(entity: Type): EncryptRequestOrResponse {
		if (StringHelpers.isNullOrWhiteSpace(Globals.SecurityKey)) {
			throw new Error('Security key is not set.');
		}

		try {

			const jsonData = JSON.stringify(entity);
			const iv = CryptoJS.lib.WordArray.random(16);
			const key = CryptoJS.enc.Utf8.parse(Globals.SecurityKey.padEnd(32).substring(0, 32));

			const encrypted = CryptoJS.AES.encrypt(jsonData, key, {
				iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			});

			const combined = iv.concat(encrypted.ciphertext);

			return {
				cipherText: CryptoJS.enc.Base64.stringify(combined)
			};

		} catch (error) {
			throw new Error(`An error occurred during encryption: ${error.message}`);
		}
	}

	public encryptId(id: TKey): string {
		return this.encryptValue(id);
	}

	public encryptIdByString(id: string): string {
		if (!StringHelpers.isNullOrWhiteSpace(id))
		return this.encryptValue(id);
	}
	public encryptIdByNumber(id: number): string {
		if (!StringHelpers.isNumberNullOrUndefined(id))
		return this.encryptValue(id);
	}

	private encryptValue<Type>(value: Type): string {
		if (StringHelpers.isNullOrWhiteSpace(Globals.SecurityKey)) {
			throw new Error('Security key is not set.');
		}

		try {
			const jsonData = JSON.stringify(value);
			const iv = CryptoJS.lib.WordArray.random(16);
			const key = CryptoJS.enc.Utf8.parse(Globals.SecurityKey.padEnd(32).substring(0, 32));

			const encrypted = CryptoJS.AES.encrypt(jsonData, key, {
				iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});

			const combined = iv.concat(encrypted.ciphertext);

			return CryptoJS.enc.Base64.stringify(combined);
		} catch (error) {
			throw new Error(`An error occurred during encryption: ${error.message}`);
		}
	}

	private performDecryption(encryptedData: EncryptRequestOrResponse): string {
		if (StringHelpers.isNullOrWhiteSpace(Globals.SecurityKey)) {
			throw new Error('Security key is not set.');
		}

		try {
			const cipherBytes = CryptoJS.enc.Base64.parse(encryptedData.cipherText);
			const cipherArray = CryptoJS.lib.WordArray.create(cipherBytes.words, cipherBytes.sigBytes);

			const ivSize = 16;
			const iv = CryptoJS.lib.WordArray.create(cipherArray.words.slice(0, ivSize / 4), ivSize);
			const encryptedContent = CryptoJS.lib.WordArray.create(
				cipherArray.words.slice(ivSize / 4),
				cipherArray.sigBytes - ivSize
			);

			const key = CryptoJS.enc.Utf8.parse(Globals.SecurityKey.padEnd(32).substring(0, 32));

			const decryptedBytes = CryptoJS.AES.decrypt(
				{ciphertext: encryptedContent},
				key,
				{iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}
			);

			const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

			if (!decryptedData) {
				throw new Error('Decryption failed. Invalid encrypted data or security key.');
			}

			return decryptedData;
		} catch (error) {
			throw new Error(`An error occurred during decryption: ${error.message}`);
		}
	}

	public decryptForSingleResult(encryptedData: EncryptRequestOrResponse): ResponseWrapper<TEntity> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(encryptedData)) {

			try {
				const decryptedData = this.performDecryption(encryptedData);
				const parsedData = JSON.parse(decryptedData);
				return parsedData as ResponseWrapper<TEntity>;
			} catch (error) {
				throw new Error(`Error in decryptForSingleResult: ${error.message}`);
			}
		}
	}

	public decryptForSingleResultByType<Type>(encryptedData: EncryptRequestOrResponse): ResponseWrapper<Type> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(encryptedData)) {

			try {
				const decryptedData = this.performDecryption(encryptedData);
				const parsedData = JSON.parse(decryptedData);
				return parsedData as ResponseWrapper<Type>;
			} catch (error) {
				throw new Error(`Error in decryptForSingleResult: ${error.message}`);
			}
		}
	}

	public decryptForMultipleResult(encryptedData: EncryptRequestOrResponse): ResponseWrapper<TEntity[]> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(encryptedData)) {

			try {
				const decryptedData = this.performDecryption(encryptedData);
				const parsedData = JSON.parse(decryptedData);
				return parsedData as ResponseWrapper<TEntity[]>;
			} catch (error) {
				throw new Error(`Error in decryptForMultipleResult: ${error.message}`);
			}
		}
	}

	public decryptForMultipleResultByType<Type>(encryptedData: EncryptRequestOrResponse): ResponseWrapper<Type[]> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(encryptedData)) {

			try {
				const decryptedData = this.performDecryption(encryptedData);
				const parsedData = JSON.parse(decryptedData);
				return parsedData as ResponseWrapper<Type[]>;
			} catch (error) {
				throw new Error(`Error in decryptForMultipleResult: ${error.message}`);
			}
		}
	}
}
