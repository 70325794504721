import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {StringHelpers} from '../../shared/helpers/string-helpers';
import {Globals} from '../../shared/globals';
import {CacherService} from '../../shared/services/cacher/cacher.service';
import {AuthService} from './auth.service';
import {UserInformation} from './models/login-response.model';
import {CompanyService} from '../services/company/company.service';
import {PermissionService} from '../../shared/services/permissions/permission.service';
import {ObjectHelpers} from '../../shared/helpers/object-helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	public constructor(private _router: Router,
					   private _authService: AuthService,
					   private _companyService: CompanyService,
					   private _permissionService: PermissionService,
					   private _cacherService: CacherService) {
	}

	public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

		if (StringHelpers.isNullOrWhiteSpace(Globals.AuthToken) &&
			localStorage.length === 0 ||
			this._cacherService.hasKey('isRefreshTriggered')) {
			await this.logout();
			return false;
		}

		let authToken: string = Globals.AuthToken;

		if (StringHelpers.isNullOrWhiteSpace(authToken)) {
			const userInformation = await this._cacherService.getByKey<UserInformation>('UserInformation');

			if (ObjectHelpers.isNullUndefinedOrEmpty(userInformation))
				await this.logout();
			authToken = userInformation.accessToken;

			Globals.BranchIds = await this._cacherService.getByKey<number[]>('BranchIds');
			Globals.CompanyId = await this._cacherService.getByKey<number>('CompanyId');
			Globals.AuthToken = authToken;
			Globals.RefreshToken = userInformation.refreshToken;

			// make api call to get securityKey from backend

			if (!ObjectHelpers.isNullUndefinedOrEmpty(userInformation.employeeUserInformation[0].systemUser)) {
				this._permissionService.addRoleWithPermissions(userInformation.employeeUserInformation[0]);
			} else {
				await this.logout();
				return false;
			}
		}

		if (StringHelpers.isNullOrWhiteSpace(authToken) || this._authService.isTokenExpired(authToken)) {
			await this.logout();
			return false;
		}
		return true;
	}



	private async logout(): Promise<void> {
		this._authService.logout();
		this._permissionService.flushRolesAndPermissions();
		await this._router.navigate(['/login']);
	}
}
