import { Injectable } from '@angular/core';
import {ApiService} from '../../../../services/shared/api.service';
import {CacherService} from '../../../shared/services/cacher/cacher.service';
import {NotificationsService} from '../../../shared/services/notifications/notifications.service';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../shared/globals';
import {GenericEntityService} from '../../../../services/shared/generic-entity.service';
import {IEmployee} from '../../../../models/auth/employee';
import {RequestOptions} from '../../../../models/shared/request-options.model';
import {StringHelpers} from '../../../shared/helpers/string-helpers';
import {from, Observable, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {Logger} from '../../../shared/services/logger/logger';
import {ColumnsModel} from '../../../../models/dealership/columns/columns.model';
import {ResponseWrapper} from '../../../../models/IResponseWrapper';
import {IEmployeePosition} from '../../../../models/auth/employee-position';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends GenericEntityService<IEmployee, string> {
	public employeeDetails: IEmployee;
	public employeePositions: IEmployeePosition[];

	constructor(protected apiService: ApiService,
				protected cacherService: CacherService,
				protected notificationsService: NotificationsService,
				private http: HttpClient) {
		const baseUrl: string = Globals.DealershipAuthApiBaseUrl + '/Employees/v1';
		super(notificationsService, apiService, baseUrl);
	}

	private static async getUserProfileImageUploadModel(branchId: number, file: File): Promise<FormData> {
		const formData = new FormData();
		formData.append('imageFile', file, file.name);

		formData.append('details', JSON.stringify({branchId}));

		return formData;
	}

	public getEmployeesGridColumnProperties(): Promise<Array<ColumnsModel>> {
		return this.http.get<Array<ColumnsModel>>('assets/demo/data/employees-grid-columns.json')
			.toPromise()
			.then(res => res);
	}

	public getAllEmployeePositions(requestOptions: RequestOptions): Observable<ResponseWrapper<IEmployeePosition[]>> {
		requestOptions.method = 'get';
		const endpoint: string = `EmployeePositions`;
		requestOptions.baseUrl = Globals.DealershipAuthApiBaseUrl;
		return this.apiService.apiRequest<ResponseWrapper<IEmployeePosition[]>>(endpoint, '', requestOptions);
	}

	public startUpload(branchId: number, userProfileId: string, file: File, requestOptions: RequestOptions = new RequestOptions()) {
		if (StringHelpers.isNullOrWhiteSpace(userProfileId)) {
			// return this.throwObservableError('UPDATE', `User Profile Id cannot be null or empty.`);
		}

		return from(EmployeeService.getUserProfileImageUploadModel(branchId, file)).pipe(
			mergeMap((formData: FormData) => this.apiService.apiRequest<IEmployee>(
				`ImageUpload?employeeId=${userProfileId}`, formData, {
					baseUrl: 	  	  'https://staging-dealership.vmgonlineservices.co.za/auth/Employees',
					method: 	  	  'post',
					timeout:	  	  requestOptions.timeout,
					showLoader:   	  false,
					useBranchHeader: true
				}
			)),
			catchError(error => {
				const message: string = `Failed trying to upload specified User Profile Image`;
				Logger.logError(`[UPLOAD USER PROFILE IMAGE] ${message}:`, error);
				return throwError(error);
			})
		);
	}
}
