<tr-viewer
	#reportPreviewer
	[containerStyle]="viewerContainerStyle"
	[serviceUrl]="baseUrl"
	[reportSource]="reportConfig"
	[viewMode]="'PRINT_PREVIEW'"
	[scaleMode]="'FIT_PAGE_WIDTH'"
	[authenticationToken]="reportDetails['Token']"
	[scale]="1.0">
</tr-viewer>

