import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginRequestModel} from '../models/login-request.model';
import {ObjectHelpers} from '../../../shared/helpers/object-helpers';
import {StringHelpers} from '../../../shared/helpers/string-helpers';
import {lastValueFrom} from 'rxjs';
import {NotificationsService} from '../../../shared/services/notifications/notifications.service';
import {CacherService} from '../../../shared/services/cacher/cacher.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {UserInformation} from '../models/login-response.model';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {BRANCH_IDS, COMPANY_ID} from '../../services/company/company.service';
import {PermissionService} from '../../../shared/services/permissions/permission.service';
import {RequestOptions} from '../../../../models/shared/request-options.model';
import {Globals} from '../../../shared/globals';
import {CacheType} from '../../../shared/services/cacher/caching-options';
import {ErrorResponse} from '../../../../services/shared/api.service';

@Component({
	selector: 'app-inactive-login',
	templateUrl: './inactive-login.component.html',
	styleUrls: ['./inactive-login.component.scss']
})
export class InactiveLoginComponent implements OnInit {

	public loginForm: FormGroup;
	public loginDetails: LoginRequestModel = new LoginRequestModel();
	public passwordType: string = 'password';
	public showPassword: boolean = false;
	public capsLock: boolean;
	public company: number;

	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _notificationsService: NotificationsService,
		private _cacherService: CacherService,
		private _permissionService: PermissionService,
		private _formBuilder: FormBuilder,
		private ref: DynamicDialogRef) {
	}

	public async ngOnInit(): Promise<void> {
		this.getLocalStorageValues().then(value => {
			this.clearLocalStorage();
		});
		this.initializeForm();

	}

	@HostListener('window:beforeunload', ['$event'])
	refresh($event: any) {
		this.redirectToLogin().then();
	}

	private async getLocalStorageValues(): Promise<void> {

		await this._cacherService.getByKey<UserInformation>('UserInformation').then(async (user) => {
			this.loginDetails.emailAddress = user?.user.emailAddress;

			this._cacherService.getByKey<number>('CompanyId').then(company => {
				this.company = company;
			});

		}).catch((e) => {
			console.error('cannot get local storage values: ' + e);
		});
	}

	private clearLocalStorage(): void {
		if (!ObjectHelpers.isNullUndefinedOrEmpty(this.loginDetails) && !StringHelpers.isNumberNullOrUndefined(this.company)) {
			localStorage.clear();
		}
	}

	public isLoginForm(): boolean {
		return this.validateLoginForm();
	}

	public async login(): Promise<void> {
		if (!this.isLoginForm()) {
			return;
		}

		const login$ = this._authService.login(this.loginDetails);

		await lastValueFrom(login$).then(async (userInformation) => {
			this._authService.loginResponse = userInformation;

			this._permissionService.flushRolesAndPermissions();
			this._permissionService.addRoleWithPermissions(this._authService.loginResponse.employeeUserInformation[0]);

			Globals.AuthToken = userInformation.accessToken;
			Globals.RegionId = userInformation.companyAddress.regionId;

			this._cacherService.setData('UserInformation', userInformation, {cacheType: CacheType.Local});

			await this.companyCheck()
				.catch(async (err: ErrorResponse) => {
					this._authService.logout();
				});
		}).catch(() => {
			this._authService.logout();
		});
	}

	private async companyCheck(): Promise<void> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(this._authService.loginResponse.employeeUserInformation[0])) {
			if (this._authService.loginResponse.employeeUserInformation.length === 1) {

				Globals.CompanyId = this._authService.loginResponse.employeeUserInformation[0].companyId;
				Globals.BranchIds = [this._authService.loginResponse.employeeUserInformation[0].systemUser.branchId];

				this._cacherService.setData(BRANCH_IDS, Globals.BranchIds, {cacheType: CacheType.Local});
				this._cacherService.setData(COMPANY_ID, Globals.CompanyId, {cacheType: CacheType.Local});

			} else {
				this._notificationsService.showErrorToastMessage('Login failed', 'This user account does not have any active Dealership instances available. ' +
					'Please contact support for more information.');
			}

			this.ref.close();
		}
	}

	public async redirectToLogin(): Promise<void> {

		this.ref.close();
		this.ref.destroy();

		this._authService.logout();
		await this._router.navigate(['/login']);
	}

	private validateLoginForm(): boolean {

		if (StringHelpers.isNullOrWhiteSpace(this.loginDetails.password)) {
			this._notificationsService.showErrorToastMessage('Alert', 'Password required.');
			return false;
		}
		return true;
	}

	private initializeForm(): void {
		this.loginForm = this._formBuilder.group({
			password: ['', Validators.compose([Validators.required])]
		});
	}

	public async valueChanged(field: string): Promise<void> {
		const loginDetails = this.loginForm.value as LoginRequestModel;

		if (!ObjectHelpers.isNullOrUndefined(loginDetails)) {
			this.populateFromForm(field);
		}
	}

	private populateFromForm(field: string): void {
		if (field === 'password') {
			this.loginDetails.password = this.loginForm.controls.password.value;
		}
	}

	public togglePassword(): void {
		this.passwordType = this.showPassword ? 'password' : 'text';
		this.showPassword = !this.showPassword;
	}

	public checkCapsLock(event): void {
		if (ObjectHelpers.functionExists(event, 'getModifierState')) {
			this.capsLock = event.getModifierState('CapsLock');
		}
	}
}
