import {SelectItem} from 'primeng/api';

export class EmptySelectItem {
	public static setEmptySelectItemDefaultNull(): SelectItem {
		return { label: '\u00A0', value: null };
	}
	public static setEmptySelectItemDefaultZero(): SelectItem {
		return { label: '\u00A0', value: 0 };
	}
}
