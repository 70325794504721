import {environment} from '../../../environments/environment';
import {IBranch} from '../../models/global/branch';
import {ISystemUser} from '../../models/auth/system-user';
import {IEmployee} from "../../models/auth/employee";
import {User} from "../authentication/login/models/login-response.model";

export class Globals {

	// App Version
	public static AppVersion:		string = '';

	// Authentication
	public static UserId: 			string = '';
	public static AuthToken: 		string = '';
	public static RefreshToken: 	string = '';
	public static UserProfile: 		'UserProfileModel';
	public static Employee: 		IEmployee;
	public static SystemUserProfile: User;
	public static BranchProfile:	IBranch;
	public static BranchIds: number[];
	public static CompanyId:	number;
	public static RegionId: number;



	public static get AuthApiBaseUrl(): string {
		return `${environment.authBaseUrl}/auth`;
	}

	public static get DealershipAuthApiBaseUrl(): string {
		return `${environment.dealershipBaseUrl}/auth`;
	}

	public static get GlobalApiBaseUrl(): string {
		return `${environment.dealershipBaseUrl}/Global`;
	}

	public static get DealershipApiBaseUrl(): string {
		return `${environment.dealershipBaseUrl}/Dealership`;
	}

	public static get FeedsApiBaseUrl(): string {
		return `${environment.dealershipBaseUrl}/Feeds`;
	}

	public static get LeadApiBaseUrl(): string {
		return `${environment.feedsBaseUrl}/crm`;
	}

	public static get ReportApiBaseUrl(): string {
		return `${environment.dealershipBaseUrl}/report`;
	}

	public static get APIReportsUrl(): string {
		return `${environment.reportBaseUrl}/api/reports`;
	}

	public static get WorkshopApiBaseUrl(): string {
		return `${environment.dealershipBaseUrl}/workshop`;
	}

	public static get GetVehicleInfo(): string {
		return `${environment.vehicleInfoBaseUrl}/VehicleInfo`;
	}
	public static get SettingsBaseUrl(): string {
		return `${environment.dealershipBaseUrl}`;
	}
}
