<div class="grid reset-password" [formGroup]="resetEmailForm">

	<div class="col-12 md:col-12 lg:col-12 padding">
		<span style="color: #ffffff">
			Please enter the email address you would like to send the password change request to below:
		</span>
	</div>

	<div class="col-12 md:col-12 lg:col-12 padding">
		<span class="">
			<input formControlName="emailAddress"
				   type="text"
				   (click)="$event.target.select()"
				   pInputText
				   placeholder="Email address"
				   (ngModelChange)="valueChanged('emailAddress')">
		</span>
	</div>
</div>
