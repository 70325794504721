import {Injectable} from '@angular/core';
import {GenericEntityService} from '../shared/generic-entity.service';
import {ApiService} from '../shared/api.service';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {Globals} from '../../pages/shared/globals';
import {IClient} from '../../models/global/client';
import {RequestOptions} from '../../models/shared/request-options.model';
import {lastValueFrom, Observable} from 'rxjs';
import {ResponseWrapper} from '../../models/IResponseWrapper';
import {IClientInfoResponse, IClientSearch} from '../../models/global/payloads/client-search';
import {ColumnsModel} from '../../models/dealership/columns/columns.model';
import {HttpClient} from '@angular/common/http';
import {EncryptRequestOrResponse} from '../../models/shared/encrypt-request-or-response';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ClientService extends GenericEntityService<IClient, number> {

	public isEditMode: boolean = false;
	public clientSearchResponse: IClientInfoResponse[];

	constructor(
		protected apiService: ApiService,
		private cacherService: CacherService,
		protected notificationsService: NotificationsService,
		private httpClient: HttpClient
	) {
		const baseUrl: string = Globals.GlobalApiBaseUrl + '/Clients/v1';
		super(notificationsService, apiService, baseUrl
		);
	}

	public searchClients(clientKeywords: IClientSearch, requestOptions: RequestOptions): Observable<ResponseWrapper<Array<IClientInfoResponse>>> {
		requestOptions.method = 'post';
		const endpoint: string = requestOptions.endpointUrl;
		requestOptions.baseUrl = Globals.GlobalApiBaseUrl + '/v1/Clients/SearchClients';
		const encryptedPayload = this.encryptSingleEntityByType<IClientSearch>(clientKeywords);

		return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions).pipe(
			map((response: EncryptRequestOrResponse) => {
				return this.decryptForMultipleResultByType<IClientInfoResponse>(response);
			}));
	}

	public async getClientTableColumns(): Promise<Array<ColumnsModel>> {
		const clientTables$ = this.httpClient.get<Array<ColumnsModel>>('assets/demo/data/client-list-view.json');
		return await lastValueFrom(clientTables$);
	}

	public populateClientTable(client: IClientInfoResponse): IClientInfoResponse {
		return {
			client: client.client,
			address: client.address
		};
	}
}
