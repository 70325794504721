
export interface IReportConfig {
    report?: string;
    parameters?: any;
}

export enum EnumReportTypes {
    StockListBasicWithLicenseAndVinNumber = 'Stock List - Basic With License And Vin Number',
    StockListBasic = 'Stock List - Basic',
    StockListRegisterOf2ndHandGoodsByDate = 'Register of 2nd Hand Goods By Date',
    StockListDaysInStock = 'Stock Reports - Days In Stock',
    StockListOTPListDateRange= 'Stock Reports - OTP List Date Range',
    StockListNotInvoicedManagerwithEstimatedProfit = 'Stock List -Not Invoiced-ManagerwithEstimatedProfit',
    StockListWithReconCostsWithSelectedDate = 'Stock Value List with Recon Costs with Selected Date',
    StockListStockCostByType = 'Stock Reports - Stock Cost By Type',
    FinanceListFullProfitVatExclusiveAndInclusiveReport = 'Financial Reports - Full Profit (Vat Exclusive and Inclusive) Report',
    FinanceListFullProfitBySalesSourceVatExclusiveAndInclusive = 'Financial Reports - Full Profit by Sales Source (Vat Exclusive and Inclusive)',
    FinanceListFullProfitBySalesPersonVatExclusiveAndInclusive = 'Financial Reports- Full Profit by Sales Person (Vat Exclusive and Inclusive)',
	FinancialReportsSalesmansClientsReportForStockSold = 'Financial Reports - Salesmans Clients Report For Stock Sold',
	FinancialReportsIndicatorReport = 'Financial Reports - Indicator Report Invoiced'
}

export type TDefaultReportConfigs = {
    UserId: string;
    CompanyId: number,
    BranchId: number
}

export type TDateReportConfig = {
    StartDate: string,
    EndDate: string
}

