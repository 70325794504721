<div  class="layout-grid">
	<div class="menu-items">
		<p-menu [styleClass]="'w-full bg-transparent border-none py-2'" class="border-none" [model]="model"></p-menu>
	</div>
	<div class="relative">
		<div class="versions-section" *ngIf="versions">
			<div *ngIf="showVersions" style="font-size: 11px">
				<p>Authentication Version: {{versions.auth}}</p>
				<p>Dealership Version: {{versions.dealership}}</p>
				<p>Migrations Version: {{versions.migrations}}</p>
				<p>Reports Version: {{versions.reports}}</p>
				<p>Transunion Version: {{versions.transunion}}</p>
			</div>
			Version: {{versions.website}} <i class="pi pi-chevron-up text-xs cursor-pointer" (click)="showHideVersions()"></i>
		</div>
	</div>
</div>


