import {Component, OnInit} from '@angular/core';
import {AuthService} from '../login/auth.service';
import {Router} from '@angular/router';
import {CompanyBranchFeature} from '../models/company-branch-feature.model';
import {StringHelpers} from '../../shared/helpers/string-helpers';
import {CacherService} from '../../shared/services/cacher/cacher.service';
import {BRANCH_IDS, COMPANY_ID, CompanyService} from '../services/company/company.service';
import {UserInformation} from '../login/models/login-response.model';
import {ObjectHelpers} from "../../shared/helpers/object-helpers";

@Component({
	selector: 'app-company-select',
	templateUrl: './company-select.component.html',
	styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent implements OnInit {

	public companySearch: string;
	public searchActive: boolean = false;
	protected companyBranchFeatures: Array<CompanyBranchFeature> = new Array<CompanyBranchFeature>();
	protected readonly Object = Object;

	constructor(public companyService: CompanyService,
				public _cacherService: CacherService,
				private _router: Router,
				private _authService: AuthService) {
	}

	async ngOnInit(): Promise<void> {
		const allBranches: UserInformation = await this._cacherService.getByKey<UserInformation>('AllBranches');

		if (!ObjectHelpers.isNullUndefinedOrEmpty(allBranches)) {
			this.companyBranchFeatures = this.getUniqueBranches(allBranches.user.companyBranchFeatures);
		} else {
			this.companyBranchFeatures = this.getUniqueBranches(this._authService.loginResponse.user.companyBranchFeatures);
		}
	}

	private getUniqueBranches(features: CompanyBranchFeature[]): CompanyBranchFeature[] {
		const uniqueSet = new Set<string>();
		const uniqueBranches: CompanyBranchFeature[] = [];

		features.forEach(feature => {
			const uniqueKey = `${feature.companyId}-${feature.branchId}`;
			if (!uniqueSet.has(uniqueKey)) {
				uniqueSet.add(uniqueKey);
				uniqueBranches.push(feature);
			}
		});

		return uniqueBranches;
	}

	public setBackgroundImage(company: CompanyBranchFeature): any {
		let backgroundUrl: string = '../../../../assets/layout/images/company-placeholder.png';

		if ((!StringHelpers.isNullOrWhiteSpace(company.companyLogoUrl) && company.companyLogoUrl.toLowerCase() !== 'n/a')) {

			backgroundUrl = company.companyLogoUrl;
		}

		return {
			width: '250',
			height: '250',
			background: `url("${backgroundUrl}") 0 0 no-repeat`,
			'background-size': 'cover',
			overflow: 'hidden',
			'background-position': 'center'
		};
	}

	async onCompanySelect(companyBranchFeature: CompanyBranchFeature): Promise<void> {
		await this.companyService.companySelected(companyBranchFeature);
	}

	public async logout(): Promise<void> {
		this._authService.logout();
		await this._router.navigate(['/login']);
	}

	public searchToggle() {
		this.searchActive = !this.searchActive;
	}

	public enterPressed(event) {
		if (event.keyCode === 13) {
			this.searchToggle();
			event.preventDefault();
		}
	}

	public clearInput() {
		this.companySearch = '';
	}
}
