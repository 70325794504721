<div class="grid">
	<div class="col-12">

	</div>
</div>

<div>
	{{header}}
</div>
<div>
	{{content}}
</div>

<div>
	<button type="button" pButton icon="pi pi-check" label="Yes"></button>
	<button type="button" pButton icon="pi pi-close" label="No" class="ui-button-secondary"></button>
</div>



