import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Message} from "../../../../models/global/notification";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy{
	public count: number = 0;
	messages: Message[] = [
		{
			message: "Yusuf van Wyk removed a Client",
			sender: "Yusuf van Wyk",
			timestamp: "5 Minutes ago",
			read: false
		},
		{
			message: "Eugene Kleinhans created a new Supplier",
			sender: "Eugene Kleinhans",
			timestamp: "1 hours ago",
			read: false
		},
		{
			message: "Michael Boonekamp updated a Stock",
			sender: "Michael Boonekamp",
			timestamp: "2 hours ago",
			read: true
		},
		{
			message: "Zita Preuss is getting Married",
			sender: "Zita Preuss",
			timestamp: "In 2 Days",
			read: true
		},
		{
			message: "Jannie Mostert has a Birthday today!",
			sender: "Jannie Mostert",
			timestamp: "Whole Day",
			read: true
		},
		{
			message: "Jannie Mostert has a Birthday today!",
			sender: "Jannie Mostert",
			timestamp: "Whole Day",
			read: true
		},
		{
			message: "Jannie Mostert has a Birthday today!",
			sender: "Jannie Mostert",
			timestamp: "Whole Day",
			read: true
		},
		{
			message: "Jannie Mostert has a Birthday today!",
			sender: "Jannie Mostert",
			timestamp: "Whole Day",
			read: true
		},
		{
			message: "Jannie Mostert has a Birthday today!",
			sender: "Jannie Mostert",
			timestamp: "Whole Day",
			read: true
		},
		{
			message: "Jannie Mostert has a Birthday today!",
			sender: "Jannie Mostert",
			timestamp: "Whole Day",
			read: true
		},
		{
			message: "Jannie Mostert has a Birthday today!",
			sender: "Jannie Mostert",
			timestamp: "Whole Day",
			read: true
		}
	];

	@Output() hideNotifications: EventEmitter<void> = new EventEmitter<void>();

	constructor(private elRef: ElementRef) {
	}

    public ngOnInit(): void {
       try {
		   this.messages.forEach(item => {
			   if (!item.read) {
				   this.count++;
			   }
		   });

		   this.attachClickListener();
	   } catch (e) {
		   console.error('Failed to load inital call: ' + e);
	   }
    }

	public attachClickListener = (): void => {
		document.addEventListener('click', this.onClickOutside.bind(this));
	};

	public ngOnDestroy(): void {
		document.removeEventListener('click', this.onClickOutside.bind(this));
	}

	public onClickOutside(event: Event): void {
		try {
			const clickedInside = this.elRef.nativeElement.contains(event.target);

			if (!clickedInside) {
				this.hideNotifications.emit();
			}
		} catch (e) {
			console.error('Failed to emit the click: ' + e);
		}
	}

	public markAllAsRead = (): void => {
		this.messages.forEach(item => item.read = true);
	};

}
