import {Component, inject, OnInit} from '@angular/core';
import {AuthService} from '../authentication/login/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestOptions} from '../../models/shared/request-options.model';
import {interval, lastValueFrom, Observable, take} from 'rxjs';
import {Logger} from '../shared/services/logger/logger';
import {map} from 'rxjs/operators';

@Component({
	selector: 'app-email-verification',
	templateUrl: './email-verification.component.html',
	styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

	private authService = inject(AuthService);
	private activatedRoute = inject(ActivatedRoute);
	private router = inject(Router);

	protected isVerified: boolean;
	protected countdown$: Observable<number>;

	async ngOnInit(): Promise<void> {
		await this.verifyEmail();
	}

	private async verifyEmail(): Promise<void> {

		const fragment = this.activatedRoute.snapshot.fragment;
		const emailMatch = fragment ? fragment.match(/user_email=([^&]*)/) : null;
		const userEmail = emailMatch ? decodeURIComponent(emailMatch[1]) : null;

		const getVerification$ = this.authService.verifyEmail(userEmail,
			new RequestOptions({showLoader: true}));

		await lastValueFrom(getVerification$).then(verified => {
			this.isVerified = verified.isVerified;

			if (this.isVerified)
				this.startCountdown();

		}).catch(error => {
			Logger.logError('Error verifying user' + error);
		});
	}

	private startCountdown(): void {
		const countdownTime = 5;

		this.countdown$ = interval(1000).pipe(
			take(countdownTime),
			map(value => countdownTime - value));

		setTimeout(async () => {
			await this.goToLogin();
		}, countdownTime * 1000);
	}

	protected async goToLogin(): Promise<void> {
		await this.router.navigate(['/login']);
	}
}
