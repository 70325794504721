<div class="notifications-layout" style="overflow-y: scroll">
    <div class="p-4" style="color: #415467">
        <div class="top-notifications">
            <h2>Notifications <span *ngIf="count > 0" class="count-notification">{{count}}</span></h2>
        </div>
        <hr>
        <div class="bottom-notifications">
            <div class="w-full text-right mb-1">
                <button  class="flat" pButton type="button" label="Mark All As Read" (click)="markAllAsRead(); count = 0"></button>
            </div>
            <div *ngFor="let message of messages" class="p-2 message" [ngClass]="{'unread': !message.read}" (click)="message.read = true; count = count - 1" >
                <p class="m-0" [ngClass]="{'bold': !message.read}">
                    {{message.sender}}</p>
                <p [ngClass]="{'bold': !message.read}" class="my-1">{{message.message}} <span style="color: #6B778C">{{message.timestamp}}</span></p>
            </div>
        </div>
    </div>
</div>
