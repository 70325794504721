<div class="header-bar"></div>

<div *ngIf="isVerified; else notVerified" class="verified-container">
	<div class="logo-container">
		<img ngSrc="https://vmgonline.s3.af-south-1.amazonaws.com/website/resources/OSMail_HeaderLogo.png" class="vmg-logo" alt="vmg-logo" width="100" height="100">
	</div>
	<div class="confirmation-title">Email Confirmed!</div>
	<br/>
	<p class="text-center">Congratulations! Your email has been confirmed and your account is ready. Please click login to use VMG Software.</p>
	<p class="text-center">Redirecting to login in {{ countdown$ | async }} seconds...</p>
	<div class="full-width-box">
		<div class="text-center">
			<div class="welcome-message">Welcome to VMG Software!</div>
			<br/>
			<br/>
			<p-button (click)="goToLogin()">Login</p-button>
		</div>
	</div>
</div>

<ng-template #notVerified>
	<div class="verified-container">
		<div class="logo-container">
			<img ngSrc="https://vmgonline.s3.af-south-1.amazonaws.com/website/resources/OSMail_HeaderLogo.png" class="vmg-logo" alt="vmg-logo" width="100" height="100">
		</div>
		<div class="confirmation-title">Email Not Confirmed!</div>
		<br/>
		<p class="text-center">Unfortunately, your email verification was not successful. Please try again using the verification link sent to your email.</p>
		<div class="full-width-box">
			<br/>
			<br/>
			<div class="welcome-message">Need help? Email support at <a href="mailto:support@vmgsoftware.co.za">support@vmgsoftware.co.za</a>.</div>
			<div class="welcome-message">Contact support at <a href="tel:087 702 6300">087 702 6300</a>.</div>
		</div>
	</div>
</ng-template>
