import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {RequestOptions} from '../../models/shared/request-options.model';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {ResponseWrapper} from '../../models/IResponseWrapper';
import {map} from 'rxjs/operators';
import {IGenericModel} from '../../models/shared/generic-model';
import {IGetAllPayload} from '../../models/shared/get-all-payload';
import {Logger} from '../../pages/shared/services/logger/logger';

import {EncryptRequestOrResponse} from '../../models/shared/encrypt-request-or-response';
import {EncryptionService} from '../../pages/shared/services/encryption.service';

@Injectable({
	providedIn: 'root'
})
export abstract class GenericEntityService<TEntity extends IGenericModel<TKey>, TKey> extends EncryptionService<TEntity, TKey> {
	protected readonly baseUrl: string;
	public data: TEntity[] = [];

	protected constructor(protected notificationService: NotificationsService,
						  protected apiService: ApiService,
						  protected _baseUrl: string) {
		super();
		this.baseUrl = _baseUrl;
	}

	public getAll(requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity[]>> {

		try {
			requestOptions.method = 'get';
			const endpoint: string = requestOptions.endpointUrl;
			requestOptions.baseUrl = requestOptions.baseUrl ? requestOptions.baseUrl  : this.baseUrl;

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, null, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {

					const dataDecrypted = this.decryptForMultipleResult(response);
					this.data = dataDecrypted.multipleResult;

					return dataDecrypted;
				}));

		} catch (e) {
			Logger.logError('Error get all' + e);
		}
	}

	public getAllSearch(payload: IGetAllPayload, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity[]>> {

		try {
			requestOptions.method = 'post';
			const endpoint: string = requestOptions.endpointUrl + '/search';
			requestOptions.baseUrl = requestOptions.baseUrl ? requestOptions.baseUrl : this.baseUrl;

			return this.apiService.apiRequest<ResponseWrapper<TEntity[]>>(endpoint, payload, requestOptions)
				.pipe(
					map((response: ResponseWrapper<TEntity[]>) => {
						this.data = response.multipleResult;
						return response;
					})
				);
		} catch (e) {
			Logger.logError('Error get all Search' + e);
		}
	}

	public getById(id: TKey, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {
		try {
			requestOptions.method = 'get';
			const endpoint: string = requestOptions.endpointUrl ? `${requestOptions.endpointUrl}/${id}` : `${id}`;
			requestOptions.baseUrl = this.baseUrl;

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, null, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {
					return this.decryptForSingleResult(response);
				}));
		} catch (e) {
			Logger.logError('Error get by id' + e);
		}
	}

	public create(payload: TEntity, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {

		try {
			requestOptions.method = 'post';
			const endpoint: string = requestOptions.endpointUrl;
			requestOptions.baseUrl = this.baseUrl;
			const encryptedPayload = this.encryptSingleEntity(payload);

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {
					return this.decryptForSingleResult(response);
				}));
		} catch (e) {
			Logger.logError('Error create' + e);
		}
	}

	public update(payload: TEntity, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {

		try {
			requestOptions.method = 'put';
			const endpoint: string = requestOptions.endpointUrl;
			requestOptions.baseUrl = this.baseUrl;
			const encryptedPayload = this.encryptSingleEntity(payload);

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {
					return this.decryptForSingleResult(response);
				}));
		} catch (e) {
			Logger.logError('Error update' + e);
		}
	}

	public delete(payload: TEntity, requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {

		try {
			requestOptions.method = 'delete';
			const endpoint: string = requestOptions.endpointUrl;
			requestOptions.baseUrl = this.baseUrl;
			const encryptedPayload = this.encryptSingleEntity(payload);

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions)
				.pipe(
					map((response: EncryptRequestOrResponse) => {
						const decryptedSingle = this.decryptForSingleResult(response);
						const index = this.data.findIndex(item => item.id === payload.id);
						if (index !== -1) {
							this.data.splice(index, 1);
						}
						return decryptedSingle;
					}));
		} catch (e) {
			Logger.logError('Error delete' + e);
		}
	}

	public bulkMerge(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity>> {

		try {
			requestOptions.method = 'post';
			const endpoint: string = 'BulkMerge';
			requestOptions.baseUrl = this.baseUrl;

			const encryptedPayload = this.encryptMultipleEntity(payload);

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {
					return this.decryptForSingleResult(response);
				}));
		} catch (e) {
			Logger.logError('Error bulk merge' + e);
		}
	}

	public bulkCreate(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity[]>> {

		try {
			requestOptions.method = 'post';
			const endpoint: string = 'BulkCreate';
			requestOptions.baseUrl = this.baseUrl;
			const encryptedPayload = this.encryptMultipleEntity(payload);

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {
					return this.decryptForMultipleResult(response);
				}));
		} catch (e) {
			Logger.logError('Error bulk create' + e);
		}
	}

	public bulkUpdate(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity[]>> {

		try {
			requestOptions.method = 'put';
			const endpoint: string = 'BulkUpdate';
			requestOptions.baseUrl = this.baseUrl;
			const encryptedPayload = this.encryptMultipleEntity(payload);

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {
					return this.decryptForMultipleResult(response);
				}));
		} catch (e) {
			Logger.logError('Error bulk update' + e);
		}
	}

	public bulkDelete(payload: TEntity[], requestOptions: RequestOptions): Observable<ResponseWrapper<TEntity[]>> {

		try {
			requestOptions.method = 'delete';
			const endpoint: string = 'BulkDelete';
			requestOptions.baseUrl = this.baseUrl;
			const encryptedPayload = this.encryptMultipleEntity(payload);

			return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedPayload, requestOptions).pipe(
				map((response: EncryptRequestOrResponse) => {
					return this.decryptForMultipleResult(response);
				}));
		} catch (e) {
			Logger.logError('Error bulk delete' + e);
		}
	}
}
