<div class="grid align-content-center vertical-container justify-content-center" style="height: 98vh; overflow: auto">
	<div class="background-video">
		<img ngSrc="../../../../assets/layout/images/Login_BG.webp" fill alt="vmg-background">
	</div>
	<div class="col-fixed">
		<div class="card login-panel p-fluid"
			 style="height: 450px; animation: shadow-pulse 3s infinite;">

			<div class="col-12" style="padding: 0">
				<img ngSrc="../../../../assets/layout/images/VMG_Logo_New.webp" width="250" height="150"
					 style="object-fit: contain;" alt="vmg-logo">
			</div>

			<h2 style="color: #FFFFFF; margin-top: 0; font-weight: bold;" *ngIf="loginFormActive">Sign In</h2>

			<div *ngIf="loginFormActive;else show_reset_form">
				<app-log-on (isFormValid)="isLoginFormValid($event)" (loginWithEnter)="login()"
							[(value)]="loginDetails"></app-log-on>
			</div>

			<ng-template #show_reset_form>
				<app-reset-password (isFormValid)="isResetPasswordFormValid($event)"
									[(value)]="emailAddressToReset"></app-reset-password>
			</ng-template>

			<div class="col-12" *ngIf="loginFormActive">
				<span (click)="forgotPassword()" class="forgot-password">Forgot Password</span>
			</div>

			<div *ngIf="loginFormActive" class="col-12">
				<button pButton type="button" label="LOGIN" class="flex-row-reverse" style="background-color: #8BC349;width: 40%"
						icon="pi pi-sign-in"
						(click)="login()"></button>
			</div>

			<div class="col-12" *ngIf="!loginFormActive">
				<button pButton type="button" class="flex-row-reverse" label="Reset Password"
						icon="ui-icon-send" (click)="resetPassword()"></button>
			</div>

			<div class="col-12" *ngIf="!loginFormActive">
				<button class="secondary-btn flex-row-reverse" pButton type="button" label="Return to login"
						icon="ui-icon-reply"
						(click)="returnToLogin()"></button>
			</div>

		</div>
	</div>
</div>
<div class="login-support">
	<span class="mx-2">
		<i class="fa fa-phone green-icon text-base mr-1"></i>
		National landline:<a href="tel:087 702 6300" class="anchor-text-grey"> 087 702 6300</a>
	</span>
	<span class="mx-2">
		<i class="fa fa-whatsapp green-icon text-base mr-1"></i>
		Whatsapp:<a href="tel:064 503 8382" class="anchor-text-grey"> 064 503 8382</a>
	</span>
	<span class="mx-2">
		<i class="fa fa-envelope green-icon text-base mr-1"></i>
		 <a href="mailto:Support@vmgsoftware.co.za" class="anchor-text-grey">Support@vmgsoftware.co.za</a>
	</span>
</div>
