import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {TopbarComponent} from './components/layout/topbar/topbar.component';
import {ProfileComponent} from './components/profile/profile.component';
import {MenuComponent, SubMenuComponent} from './components/menu/menu.component';
import {ChipsModule} from 'primeng/chips';
import {ButtonModule} from 'primeng/button';
import {LoaderComponent} from './pages/shared/components/loader/loader.component';
import {BlockUIModule} from 'primeng/blockui';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DynamicDialogModule, DynamicDialogRef, DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AlertComponent} from './pages/shared/components/alert/alert.component';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MegaMenuModule} from 'primeng/megamenu';
import {LoginComponent} from './pages/authentication/login/login.component';
import {CompanySelectComponent} from './pages/authentication/company-select/company-select.component';
import {CardModule} from 'primeng/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'primeng/tooltip';
import {ResetPasswordComponent} from './pages/authentication/components/reset-password/reset-password.component';
import {LogOnComponent} from './pages/authentication/components/log-on/log-on.component';
import {FilterPipe} from './pages/shared/pipes/filter.pipe';
import {NgxPermissionsModule} from 'ngx-permissions';
import {MatIconModule} from '@angular/material/icon';
import {TableModule} from 'primeng/table';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {FieldsetModule} from 'primeng/fieldset';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CalendarModule} from 'primeng/calendar';
import {MultiSelectModule} from 'primeng/multiselect';
import {ClientSearchComponent} from './pages/shared/components/client-search/client-search.component';
import {InactiveLoginComponent} from './pages/authentication/login/inactive-login/inactive-login.component';

import {HttpClientModule} from '@angular/common/http';
import {TabViewModule} from 'primeng/tabview';
import {
	AdvertReportSelectionComponent
} from './pages/shared/components/advert-report-selection/advert-report-selection.component';
import {MessagesModule} from 'primeng/messages';
import {SharedModule} from './pages/shared/shared.module';
import {ReportsComponent} from './pages/modules/reports/reports.component';
import {TelerikReportingModule} from '@progress/telerik-angular-report-viewer';
import { MainPageComponent } from './pages/modules/main-page/main-page.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import {NgOptimizedImage} from "@angular/common";


@NgModule({
	declarations: [
		AppComponent,
		ReportsComponent,
		AppMainComponent,
		TopbarComponent,
		ProfileComponent,
		MenuComponent,
		SubMenuComponent,
		LoaderComponent,
		AlertComponent,
		LoginComponent,
		CompanySelectComponent,
		ResetPasswordComponent,
		LogOnComponent,
		FilterPipe,
		ClientSearchComponent,
		InactiveLoginComponent,
		AdvertReportSelectionComponent,
  MainPageComponent,
  EmailVerificationComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ScrollPanelModule,
		BrowserAnimationsModule,
		ChipsModule,
		ButtonModule,
		BlockUIModule,
		ProgressSpinnerModule,
		DynamicDialogModule,
		DialogModule,
		ToastModule,
		ConfirmDialogModule,
		MegaMenuModule,
		CardModule,
		ReactiveFormsModule,
		TooltipModule,
		FormsModule,
		PanelModule,
		SharedModule,
		DropdownModule,
		CheckboxModule,
		NgxPermissionsModule.forRoot(),
		MatIconModule,
		ToggleButtonModule,
		TableModule,
		InputTextareaModule,
		FieldsetModule,
		InputSwitchModule,
		CalendarModule,
		TableModule,
		ToggleButtonModule,
		PanelModule,
		ListboxModule,
		MultiSelectModule,
		TabViewModule,
		MessagesModule,
		TelerikReportingModule,
		NgOptimizedImage
	],
	providers: [
		DialogService,
		DynamicDialogRef,
		DynamicDialogConfig,
		MessageService,
		ConfirmationService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
