import {Injectable} from '@angular/core';
import {CompanyBranchFeature} from '../../pages/authentication/models/company-branch-feature.model';
import {GenericEntityService} from '../shared/generic-entity.service';
import {IBranch} from '../../models/global/branch';
import {User} from '../../pages/authentication/login/models/login-response.model';
import {ApiService} from '../shared/api.service';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {Globals} from '../../pages/shared/globals';
import {StringHelpers} from '../../pages/shared/helpers/string-helpers';
import {ObjectHelpers} from '../../pages/shared/helpers/object-helpers';
import {SelectItem, SelectItemGroup} from 'primeng/api';
import {RequestOptions} from '../../models/shared/request-options.model';
import {catchError, mergeMap} from 'rxjs/operators';
import {IEmployee} from '../../models/auth/employee';
import {Logger} from '../../pages/shared/services/logger/logger';
import {BehaviorSubject, from, throwError} from 'rxjs';
import {EmptySelectItem} from "../../pages/shared/empty-select-item";


export const BRANCH: string = 'Branch';
export const ADDRESS: string = 'Address';
export const ENABLED_FEATURES: string = 'EnabledFeatures';
export const APP_VERSION: string = 'AppVersion';
export const COUNTRY_CURRENCY_AND_LOCALE: string = 'CountryCurrencyAndLocale';

@Injectable({
	providedIn: 'root'
})
export class BranchService extends GenericEntityService<IBranch, number> {

	constructor(protected apiService: ApiService,
				protected notificationService: NotificationsService,
				private cacherService: CacherService) {
		const endpointUrl: string = Globals.GlobalApiBaseUrl +  '/Branches/v1';
		super(notificationService, apiService, endpointUrl);
	}
	public companies: Array<CompanyBranchFeature> = [];

	public userCompanies: User;
	public enabledFeatures: Array<any> = [];
	public allBranches: Array<SelectItem> = new Array<SelectItem>(EmptySelectItem.setEmptySelectItemDefaultNull());

	private readonly BranchCacheKey: string = 'Branch';
	private branchIdsSubject = new BehaviorSubject<number[]>([]);
	branchIds$ = this.branchIdsSubject.asObservable();

	private static async getBranchImageUploadModel(file: File): Promise<FormData> {
		const formData = new FormData();
		formData.append('imageFile', file, file.name);

		return formData;
	}

	setBranchIds(branchIds: number[]) {
		this.branchIdsSubject.next(branchIds);
	}

	getBranchIds(): number[] {
		return this.branchIdsSubject.getValue();
	}

	public async getBranchCacheValues(): Promise<void> {
		if (this.cacherService.hasKey(this.BranchCacheKey)) {
			// const branch: IBranch = await this.cacherService.getByKey<BranchModel>(this.BranchCacheKey);
			// Globals.BranchProfile = _.clone(branch);
		}
	}


	public async validateBranchRequiredFields(): Promise<{ message: string, warningVisible: boolean }> {
		let tooltipMessage: string = 'Missing fields: \n';
		let isVisible: boolean = false;

		if (this.cacherService.hasKey(BRANCH)) {
			await this.getBranchCacheValues().then(() => {
				if (!ObjectHelpers.isNullUndefinedOrEmpty(Globals.BranchProfile)) {
					if (StringHelpers.isNullOrWhiteSpace(Globals.BranchProfile.name)) {
						tooltipMessage += ' \n Branch Name';
						isVisible = true;
					}
					if (StringHelpers.isNullOrWhiteSpace(Globals.BranchProfile.contactOneEmailAddress)) {
						tooltipMessage += ' \n Contact Person One Email Address';
						isVisible = true;
					}
					if (StringHelpers.isNullOrWhiteSpace(Globals.BranchProfile.contactOneWorkNumber)) {
						tooltipMessage += '\n Contact Person One Work Number';
						isVisible = true;
					}
				}
			});
		}

		return {
			message: 		tooltipMessage,
			warningVisible: isVisible
		};
	}

	public startUpload(branchId: number, file: File, requestOptions: RequestOptions = new RequestOptions()) {

		return from(BranchService.getBranchImageUploadModel(file)).pipe(
			mergeMap((formData: FormData) => this.apiService.apiRequest<IEmployee>(
				`ImageUpload?Id=${branchId}`, formData, {
					baseUrl: 	  	  Globals.GlobalApiBaseUrl + '/Branches',
					method: 	  	  'post',
					timeout:	  	  requestOptions.timeout,
					showLoader:   	  false,
					useBranchHeader: true
				}
			)),
			catchError(error => {
				const message: string = `Failed trying to upload specified Company Image`;
				Logger.logError(`[UPLOAD COMPANY IMAGE] ${message}:`, error);
				return throwError(error);
			})
		);
	}
}
