<div class="grid p-fluid login-page" [formGroup]="loginForm">
	<div class="field col-12 padding">
		<span>
			<input formControlName="emailAddress"
				   type="email"
				   class="input"
				   pInputText
				   placeholder="Email address"
				   (click)="$event.target.select()"
				   (ngModelChange)="valueChanged('emailAddress')"
				   (keyup.enter)="loginWithKeypress()">
		</span>
	</div>
	<div class="field col-12 padding">
		<div class="p-inputgroup">
			<span class="login-password">
				<input [type]="passwordType"
					   formControlName="password"
					   class="input password"
					   pInputText
					   placeholder="Password"
					   (click)="$event.target.select()"
					   (ngModelChange)="valueChanged('password')"
					   (keyup)="checkCapsLock($event)"
					   (keyup.enter)="loginWithKeypress()">
			</span>
			<span class="p-inputgroup-addon" style="cursor: pointer">
				<div class="col-2" style="padding-top: 0; padding-bottom: 0; margin-right: 10px;" (click)="togglePassword()">
					<label *ngIf="showPassword" class="fa fa-eye show-password-icon"></label>
					<label *ngIf="!showPassword" class="fa fa-eye-slash show-password-icon"></label>
				</div>
			</span>
		</div>
		<span *ngIf="capsLock" class="caps-warning">
			<i class="ui-icon-lock"
			   style="vertical-align:baseline;font-size:0.7rem;"></i> CAPS LOCK is On.
		</span>
	</div>
</div>
